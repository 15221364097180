import React from "react";
import { CodeSquare, PencilRuler } from "lucide-react";
import { Fade } from "react-awesome-reveal";

const Hero = ({ subHeader, header, description, bgHero }) => {
  return (
    <Fade>
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-4 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h3 className="max-w-2xl mb-4 text-2xl font-bold text-gray-500 tracking-tight leading-none md:text-2xl xl:text-2xl dark:text-gray-400">
              {subHeader}
            </h3>
            <h1 className="max-w-2xl mb-4 text-8xl font-extrabold tracking-tight leading-none md:text-8xl xl:text-8xl dark:text-white">
              {header}
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              {description}
            </p>
            <a
              href="#viewresources"
              className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-full bg-black hover:bg-gray-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              View Resources
              <svg
                className="w-5 h-5 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </a>
            <div className="mt-16 flex">
              <div className="flex align-middle mr-16">
                <button
                  type="button"
                  className="cursor-default text-gray-900 bg-gray-100 hover:bg-gray-200 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2"
                >
                  <CodeSquare />
                </button>
                120 Development <br />
                Resources
              </div>
              <div className="flex align-middle">
                <button
                  type="button"
                  className="cursor-default text-gray-900 bg-gray-100 hover:bg-gray-200 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2"
                >
                  <PencilRuler />
                </button>
                240 Designer <br />
                Resources
              </div>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={bgHero} alt="mockup" />
          </div>
        </div>
      </section>
    </Fade>
  );
};

export default Hero;
