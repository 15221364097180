import React from "react";
// import { createRoot } from "react-dom/client";
import Markdown from "react-markdown";

const Blog = () => {
  const markdown = "# Hi, *Pluto*!";
  return (
    <>
      <Markdown>{markdown}</Markdown>
    </>
  );
};

export default Blog;
