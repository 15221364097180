import React from "react";
import CategoryCard from "../CategoryCard/CategoryCard";

const CategoryList = ({ CATEGORYLIST, productCount }) => {
  // const [categoryListCount, setSategoryListCount] = useState([]);

  // useEffect(() => {
  //   const finalCount = [];

  //   productCount.forEach((element) => {
  //     CATEGORYLIST.forEach((category) => {
  //       if (element.category === category.value) {
  //         finalCount.push({ ...category, count: element.count });
  //       }
  //     });
  //   });
  //   setSategoryListCount(finalCount);
  // }, [CATEGORYLIST, productCount]);
  return (
    <>
      <div className="relative" id="viewresources">
        <div className="mt-6 flex items-center w-full">
          <div className="w-full grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-3 md:gap-y-10 lg:gap-x-8">
            {CATEGORYLIST.map((category, i) => (
              <CategoryCard
                key={`category-${i}`}
                category={category}
                index={i}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryList;
