import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDu3Sq82vnZQVnVKRoniUv1Ng64uYm8hvs",
  authDomain: "flusk-e6ebd.firebaseapp.com",
  projectId: "flusk-e6ebd",
  storageBucket: "flusk-e6ebd.appspot.com",
  messagingSenderId: "496373566784",
  appId: "1:496373566784:web:02db6a1f847b9cc45ffea5",
  measurementId: "G-QCXZEQTH0K",
});

export const auth = app.auth();
export default app;
