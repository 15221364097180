import "./App.css";
import Navbars from "./components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import { Flowbite } from "flowbite-react";
import { useEffect } from "react";
import { initFlowbite } from "flowbite";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Favourites from "./Pages/Favourites";
import Category from "./Pages/Category";
import Footer from "./components/Footer/Footer";
import SignUp from "./Pages/SignUp";
import { AuthProvider } from "./contexts/AuthContext";
import AddResources from "./Pages/AddResources";
// import Blog from "./Pages/Blog";
import AllBlogs from "./Pages/AllBlogs";
import SingleProduct from "./Pages/SingleProduct";
import { HelmetProvider } from "react-helmet-async";
import Blog from "./Pages/Blog";
import Courses from "./Pages/Courses";
import AddCourses from "./Pages/AddCourses";
import CourseCategory from "./Pages/CourseCategory";
import ForgetPassword from "./Pages/ForgetPassword";
import PrivacyPolicy from "./Pages/PrivacyPolicy";

const customTheme = {
  button: {
    color: {
      primary: "dark",
    },
  },
};

const helmetContext = {};

function App() {
  useEffect(() => {
    initFlowbite();
  }, []);
  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Flowbite theme={{ theme: customTheme }}>
          <BrowserRouter>
            <AuthProvider>
              <Navbars />
              <Routes>
                <Route index element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/forget-password" element={<ForgetPassword />} />
                <Route path="/favourite" element={<Favourites />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/category/:categoryType" element={<Category />} />
                <Route path="/add-resources" element={<AddResources />} />
                <Route path="/blog" element={<AllBlogs />} />
                <Route path="/blog/:id" element={<Blog />} />
                <Route path="/course" element={<Courses />} />
                <Route path="/add-course" element={<AddCourses />} />
                <Route
                  path="/course-category/:categoryType"
                  element={<CourseCategory />}
                />
                <Route
                  path="/category/:categoryType/:resource"
                  element={<SingleProduct />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              </Routes>
              <Footer />
            </AuthProvider>
          </BrowserRouter>
        </Flowbite>
      </HelmetProvider>
    </>
  );
}

export default App;
