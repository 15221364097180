import React from "react";
import { Link } from "react-router-dom";
import Product from "../Product/Product";

const ProductList = ({ title, href, products }) => {
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="py-12" id={title}>
            <div className="md:flex md:items-center md:justify-between mb-4">
              <div className="max-w-2xl px-4 lg:max-w-4xl lg:px-0">
                {title ? (
                  <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                    {title}
                  </h1>
                ) : null}
              </div>

              {href ? (
                <Link
                  href={href}
                  className="hidden text-sm font-medium text-blue-600 hover:text-blue-500 md:block"
                >
                  Go to collection <span aria-hidden="true">&rarr;</span>
                </Link>
              ) : null}
            </div>

            <div className="relative">
              <div className="mt-6 flex items-center w-full">
                <div className="w-full grid grid-cols-2 gap-x-4 gap-y-10 sm:gap-x-6 md:grid-cols-4 md:gap-y-10 lg:gap-x-8">
                  {products.map((product, i) => (
                    <Product key={`product-${i}`} product={product} index={i} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductList;
