import IconPNG from "./../assets/3d-dynamic-color.png";
import DesignSystemPNG from "./../assets/figma-dynamic-color.png";
import IllustrationPNG from "./../assets/pencil-dynamic-color.png";
import InspirationPNG from "./../assets/bulb-dynamic-color.png";
import ColorToolsPNG from "./../assets/roll-brush-dynamic-color.png";
import TypographyPNG from "./../assets/text-dynamic-color.png";
import PhotoVideoPNG from "./../assets/picture-dynamic-color.png";
import MockUpKitPNG from "./../assets/computer-dynamic-color.png";
import PatternBGPNG from "./../assets/location-dynamic-color.png";
import UXToolsPNG from "./../assets/mobile-dynamic-color.png";
import AIToolsPNG from "./../assets/minecraft-dynamic-color.png";
import AccessibilityPNG from "./../assets/boy-dynamic-color.png";
import OtherPNG from "./../assets/takeaway-cup-dynamic-color.png";

export const CATEGORYLIST = [
  {
    id: 1,
    label: "Icons",
    value: "icons",
    image: IconPNG,
    count: 50,
    sub_category: [
      {
        label: "Guides",
        value: "guides",
      },
      {
        label: "Free Icons",
        value: "free_icons",
      },
      {
        label: "Paid",
        value: "paid",
      },
      {
        label: "Animated",
        value: "animated",
      },
      {
        label: "Search Icons",
        value: "search_icons",
      },
    ],
  },
  {
    id: 2,
    label: "Design System/UI Kit",
    value: "ui_kits",
    image: DesignSystemPNG,
    count: 28,
    sub_category: [
      {
        label: "Guides",
        value: "guides",
      },
      {
        label: "Free Icons",
        value: "free_icons",
      },
      {
        label: "Paid",
        value: "paid",
      },
      {
        label: "Animated",
        value: "animated",
      },
      {
        label: "Search Icons",
        value: "search_icons",
      },
    ],
  },
  {
    id: 3,
    label: "Illustrations",
    value: "illustrations",
    image: IllustrationPNG,
    count: 44,
    sub_category: [
      {
        label: "Guides",
        value: "guides",
      },
      {
        label: "Free Illustrations",
        value: "free_illustrations",
      },
      {
        label: "Characters",
        value: "characters",
      },
      {
        label: "3D Illustrations",
        value: "3d_illustrations",
      },
      {
        label: "Paid Illustrations",
        value: "paid_illustrations",
      },
    ],
  },
  {
    id: 4,
    label: "Inspirations",
    value: "inspiration",
    image: InspirationPNG,
    count: 10,
    sub_category: [
      {
        label: "Guides",
        value: "guides",
      },
      {
        label: "Landing Page",
        value: "landing_page",
      },
      {
        label: "UI Designs",
        value: "ui_desings",
      },
      {
        label: "Web Design",
        value: "web_design",
      },
      {
        label: "SAAS Designs",
        value: "saas_designs",
      },
      {
        label: "Mobile UI Designs",
        value: "mobile_ui_designs",
      },
    ],
  },
  {
    id: 5,
    label: "Color Tools",
    value: "color_tools",
    image: ColorToolsPNG,
    count: 26,
    sub_category: [
      {
        label: "Guides",
        value: "guides",
      },
      {
        label: "Pallets",
        value: "landing_page",
      },
      {
        label: "Gradient",
        value: "ui_desings",
      },
      {
        label: "Tools",
        value: "tools",
      },
    ],
  },
  {
    id: 6,
    label: "Typography",
    value: "typography",
    image: TypographyPNG,
    count: 6,
    sub_category: [
      {
        label: "Guides",
        value: "guides",
      },
      {
        label: "Free Fonts",
        value: "free_fonts",
      },
      {
        label: "Paid",
        value: "Paid",
      },
      {
        label: "Tools",
        value: "tools",
      },
    ],
  },
  {
    id: 7,
    label: "Photo & Video",
    value: "photo_video",
    image: PhotoVideoPNG,
    count: 17,
    sub_category: [
      {
        label: "Free Photos",
        value: "free_photos",
      },
      {
        label: "Free Videos",
        value: "free_videos",
      },
      {
        label: "Free Music",
        value: "free_music",
      },
      {
        label: "Paid",
        value: "paid",
      },
    ],
  },
  {
    id: 8,
    label: "Mockups & Kits",
    value: "mockups_kits",
    image: MockUpKitPNG,
    count: 8,
    sub_category: [
      {
        label: "Device",
        value: "device",
      },
      {
        label: "UI Mockups",
        value: "ui_mockups",
      },
      {
        label: "Paid",
        value: "paid",
      },
    ],
  },
  {
    id: 9,
    label: "Pattern and BG",
    value: "patter_bg",
    image: PatternBGPNG,
    count: 0,
    sub_category: [
      {
        label: "Pattern",
        value: "pattern",
      },
      {
        label: "Backgrounds",
        value: "backgrouds",
      },
    ],
  },
  {
    id: 10,
    label: "UX Tools",
    value: "ux_tools",
    image: UXToolsPNG,
    count: 8,
    sub_category: [
      {
        label: "Prototyping",
        value: "prototyping",
      },
      {
        label: "Flow Charts",
        value: "flow_charts",
      },
      {
        label: "User Testing",
        value: "user_testing",
      },
      {
        label: "Dummy Fillers",
        value: "dummey_fillers",
      },
    ],
  },
  {
    id: 11,
    label: "AI Tools",
    value: "ai_tools",
    image: AIToolsPNG,
    count: 8,
    sub_category: [
      {
        label: "Image Generator",
        value: "imagegenerator",
      },
      {
        label: "Tools",
        value: "tools",
      },
      {
        label: "Content Generator",
        value: "contentgenerator",
      },
    ],
  },
  {
    id: 12,
    label: "Accessibility",
    value: "accessibility",
    image: AccessibilityPNG,
    count: 17,
    sub_category: [
      {
        label: "Guides",
        value: "guides",
      },
      {
        label: "Tools",
        value: "tools",
      },
      {
        label: "Accessibility Checkers",
        value: "accessibility_checkers",
      },
    ],
  },
  {
    id: 13,
    label: "Other",
    value: "other",
    image: OtherPNG,
    count: 0,
    sub_category: [
      {
        label: "Free",
        value: "free",
      },
      {
        label: "Prototyping",
        value: "prototyping",
      },
      {
        label: "Flow Charts",
        value: "flow_charts",
      },
      {
        label: "User Testing",
        value: "user_testing",
      },
      {
        label: "Dummy Fillers",
        value: "dummey_fillers",
      },
    ],
  },
];

export const COURSECATEGORYLIST = [
  {
    id: 1,
    label: "UI/UX Design",
    value: "ui_ux",
    image: OtherPNG,
    count: 0,
    sub_category: [
      {
        label: "UI Desgin",
        value: "ui_design",
      },
      {
        label: "Visual Design",
        value: "visaul_design",
      },
      {
        label: "UX",
        value: "ux",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
  },
  {
    id: 1,
    label: "Developent",
    value: "development",
    image: OtherPNG,
    count: 0,
    sub_category: [
      {
        label: "JavaScript",
        value: "javascript",
      },
      {
        label: "React",
        value: "react",
      },
      {
        label: "Angular",
        value: "angular",
      },
      {
        label: "NodeJs",
        value: "nodejs",
      },
      {
        label: "Java",
        value: "java",
      },
      {
        label: "Python",
        value: "python",
      },
      {
        label: "WordPress",
        value: "wordpress",
      },
      {
        label: "NextJs",
        value: "nextjs",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
  },
  {
    id: 3,
    label: "Cloud Development",
    value: "cloud_development",
    image: OtherPNG,
    count: 0,
    sub_category: [
      {
        label: "AWS",
        value: "aws",
      },
      {
        label: "GCP",
        value: "gcp",
      },
      {
        label: "Azure",
        value: "azure",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
  },
  {
    id: 4,
    label: "Management",
    value: "management",
    image: OtherPNG,
    count: 0,
    sub_category: [
      {
        label: "Project Management",
        value: "pm",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
  },
  {
    id: 5,
    label: "Cyber Security",
    value: "cyber_security",
    image: OtherPNG,
    count: 0,
    sub_category: [
      {
        label: "Cyber Security",
        value: "cyber_security",
      },
      {
        label: "Other",
        value: "other",
      },
    ],
  },
];

export const TAGS = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Designer",
    value: "designer",
  },
  {
    label: "Developer",
    value: "developer",
  },
  {
    label: "Management",
    value: "management",
  },
];
