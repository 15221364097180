import React, { useState } from "react";
import Select from "react-select";
import { COURSECATEGORYLIST, TAGS } from "../utils/CategoryList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../contexts/AuthContext";

const AddCourses = () => {
  const APIURL = process.env.REACT_APP_API;
  const { currentUser } = useAuth();
  const [selectedLabel, setSelectedLabel] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [imageBase64, setImageBase64] = useState("");
  const [price, setPrice] = useState("");
  const [productName, setProductName] = useState("");
  const [description, setDescription] = useState("");
  const [productUrl, setProductUrl] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  const handleTagsChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    setTags(selectedValues);
    setSelectedTags(selectedOptions);
  };

  const handleChange = (event, value) => {
    const selectedLabel = event.target.value;
    if (value === "productname") {
      setProductName(selectedLabel);
    } else if (value === "description") {
      setDescription(selectedLabel);
    } else if (value === "productUrl") {
      setProductUrl(selectedLabel);
    } else if (value === "price") {
      setPrice(selectedLabel);
    }
  };

  const handleLabelChange = (event) => {
    const selectedLabel = event.target.value;
    setSelectedLabel(selectedLabel);
    setSelectedSubCategory("");
  };

  const handleSubCategoryChange = (event) => {
    const selectedSubCategory = event.target.value;
    setSelectedSubCategory(selectedSubCategory);
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      toBase64(file)
        .then((base64Data) => {
          setImageBase64(base64Data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handlePostRequest = async () => {
    if (
      currentUser.multiFactor.user.email === "saurav540@yahoo.com" ||
      currentUser.multiFactor.user.email === "test@test.com"
    ) {
      try {
        const response = await fetch(`${APIURL}course/create`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user: currentUser.multiFactor.user.uid,
            category: selectedLabel,
            subcategory: selectedSubCategory,
            imagebase64: imageBase64,
            type: price,
            name: productName,
            description: description,
            product_url: productUrl,
            tags: tags,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        toast.success("Resurces Added", {
          position: "top-center",
          autoClose: 5000,
        });
        console.log("API Response:", data);
      } catch (error) {
        toast.error("Error while adding to favourites.", {
          position: "top-center",
          autoClose: 5000,
        });
        console.error("API Error:", error);
      }
    } else {
      toast.error("You are not an athorized user to add resources.", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <ToastContainer position="top-center" autoClose={5000} />
      <div className="py-8 px-4 mx-auto max-w-2xl lg:py-16">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
          Add a new course
        </h2>
        <form action="#">
          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="productname"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Course Name
              </label>
              <input
                type="text"
                name="productname"
                id="productname"
                value={productName}
                onChange={(e) => handleChange(e, "productname")}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Type product name"
                required=""
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="description"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Description
              </label>
              <textarea
                id="description"
                rows="2"
                onChange={(e) => handleChange(e, "description")}
                value={description}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Your description here"
              ></textarea>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="productUrl"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Course URL
              </label>
              <input
                type="text"
                name="productUrl"
                id="productUrl"
                value={productUrl}
                onChange={(e) => handleChange(e, "productUrl")}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Product brand"
                required=""
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="price"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Price Type
              </label>
              <select
                id="price"
                defaultValue={price}
                onChange={(e) => handleChange(e, "price")}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              >
                <option value="SP">Select Price</option>
                <option value="free">Free</option>
                <option value="paid">Paid</option>
                <option value="free_&_paid">Free & Paid</option>
              </select>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="category"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Category
              </label>
              <select
                id="category"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={selectedLabel}
                onChange={handleLabelChange}
              >
                <option value="SP">Select category</option>
                {COURSECATEGORYLIST.map((category) => (
                  <option key={category.id} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="subcategory"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Category
              </label>
              <select
                id="subcategory"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                value={selectedSubCategory}
                onChange={handleSubCategoryChange}
              >
                <option value="SP">Select sub category</option>
                {COURSECATEGORYLIST.find(
                  (category) => category.value === selectedLabel
                )?.sub_category.map((subCategory) => (
                  <option key={subCategory.value} value={subCategory.value}>
                    {subCategory.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="tags"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Tags
              </label>
              <Select
                id="tags"
                options={TAGS.map((tag) => ({
                  value: tag.value,
                  label: tag.label,
                }))}
                isMulti
                value={selectedTags}
                onChange={handleTagsChange}
                classNameName="text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="base64"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Base64 Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                name="base64"
                id="brand"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                placeholder="Product brand"
                required=""
              />
            </div>
          </div>
          <button
            type="submit"
            onClick={handlePostRequest}
            className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-primary-700 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
          >
            Add product
          </button>
        </form>
      </div>
    </section>
  );
};

export default AddCourses;
