/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ExternalLink, Heart } from "lucide-react";
import { useAuth } from "./../../contexts/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import defaultImage from "./../../assets/default.png";
import "react-toastify/dist/ReactToastify.css";

const Product = ({ product }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const APIURL = process.env.REACT_APP_API;
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false);

  const addToMyFav = async (e) => {
    e.preventDefault();
    if (currentUser) {
      try {
        setLoading(true);
        const response = await fetch(`${APIURL}user/addTofav`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: currentUser.multiFactor.user.email,
            favoriteId: product._id,
          }),
        });

        if (!response.ok) {
          throw new Error("Error adding user to db.");
        }

        await response.json();
        toast.success("Added to you favourite list.", {
          position: "top-center",
          autoClose: 5000,
        });
      } catch (error) {
        toast.error("Error while adding to favourites.", {
          position: "top-center",
          autoClose: 5000,
        });
        console.error(error);
      }
      setLoading(false);
    } else {
      toast.error("Please Login to add to favourites!", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const removeFav = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await fetch(`${APIURL}user/addTofav`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: currentUser.multiFactor.user.email,
          favoriteId: product._id,
        }),
      });

      if (!response.ok) {
        throw new Error("Error adding user to db.");
      }

      await response.json();
      toast.success("Removed from your favourites.", {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.error(error);
      toast.error("Error while removing from favourites", {
        position: "top-center",
        autoClose: 5000,
      });
    }
    setLoading(false);
  };

  const redirectToProductPage = () => {
    const stateData = {
      product,
    };

    navigate(`/category/${product.category}/${product.name}`, {
      state: stateData,
    });
  };

  return (
    <div className="max-w-sm cursor-pointer bg-white dark:bg-gray-800 dark:border-gray-700">
      <ToastContainer position="top-center" autoClose={5000} />
      <div onClick={redirectToProductPage}>
        {product.imagebase64 ? (
          <img
            className="h-auto max-w-full rounded-lg"
            src={product.imagebase64}
            alt=""
            style={{ width: "-webkit-fill-available" }}
          />
        ) : (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "100%",
            }}
          >
            <img
              className="h-auto max-w-full rounded-lg"
              src={defaultImage}
              alt=""
              style={{ width: "-webkit-fill-available" }}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                textAlign: "center",
                lineHeight: "1",
                transform: "translate(-50%, -50%)",
                color: "black",
                fontSize: "35px",
                fontWeight: "bold",
              }}
            >
              {product.name}
            </div>
          </div>
        )}
      </div>
      <div className="py-5">
        <Link
          to={`${product.product_url}?ref=${process.env.REACT_APP_PUBLIC_URL}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <button
            type="button"
            style={{ width: "100%" }}
            class="justify-between text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2"
          >
            Visit the asset website <ExternalLink />
          </button>
        </Link>
        <div className="flex justify-between">
          <a href="#" style={{ width: "90%" }}>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {product.name}
            </h5>
          </a>
          {location.pathname === "/favourite" ? (
            <Heart
              className="h-8 w-8"
              style={{ fill: "red", stroke: "red" }}
              onClick={removeFav}
              title="Remove from favourites"
            />
          ) : (
            <Heart
              className="h-8 w-8"
              onClick={addToMyFav}
              title="Add to favourites"
            />
          )}
        </div>
        <p className="mb-3 font-normal text-gray-500 dark:text-gray-600">
          {product.description}
        </p>
      </div>
    </div>
  );
};

export default Product;
