/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from "react";
import Logo from "./../assets/flusk-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Seo from "../components/SEO/Seo";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const email = useRef();
  const { sendPasswordReset } = useAuth();
  const [loading, setLoading] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await sendPasswordReset(email.current.value);
      toast.success("Password reset email sent! Please check your inbox.");
      const timer = setTimeout(() => {
        navigate("/login");
      }, 5000);

      return () => clearTimeout(timer);
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  };

  return (
    <>
      <Seo
        title="Flusk.io: Free Digital Assets"
        description="As designers and developers, We found ourself drowning in a sea of free resources scattered across the web. We sum-up all those resources for you!"
        name="flusk.io"
        type="login"
      />
      <section className="bg-gray-50 dark:bg-gray-900">
        <ToastContainer position="top-center" autoClose={5000} />
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img className="w-8 h-8 mr-2" src={Logo} alt="logo" />
            Flusk.io
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Reset your account
              </h1>
              <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email
                  </label>
                  <input
                    type="email"
                    name="email"
                    ref={email}
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="name@company.com"
                    required=""
                  />
                </div>
                <button
                  disabled={loading}
                  onClick={handlePasswordReset}
                  type="submit"
                  className="w-full text-white bg-black hover:bg-grey-900 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Reset password
                </button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Already have account?{" "}
                  <Link to={"/login"}>
                    <span className="font-medium text-black hover:underline dark:text-primary-500">
                      Login
                    </span>
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
