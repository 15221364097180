import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CategoryCard from "../components/CategoryCard/CategoryCard";
import { CATEGORYLIST } from "../utils/CategoryList";
import ProductList from "../components/ProductList/ProductList";
import HashLoader from "react-spinners/HashLoader";
import product from "../utils/goodies.products.json";
import Seo from "../components/SEO/Seo";
import AdSense from "../components/AdSense/AdSense";

const override = {
  display: "block",
  margin: "18% auto",
};

const Category = () => {
  const APIURL = process.env.REACT_APP_API;
  let { categoryType } = useParams();
  const [category, setCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
    let selectedCategory = CATEGORYLIST.find(
      (category) => category.value === categoryType
    );
    setCategory(selectedCategory);
  }, [categoryType]);

  useEffect(() => {
    setProducts(
      product.filter((product) => product.category === category.value)
    );
    setLoading(false);
  }, [category.value]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${APIURL}product/category?category=${categoryType}`
        );
        const result = await response.json();
        setProductCount(result.length);
        setProducts(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [APIURL, categoryType]);

  return (
    <>
      <Seo
        title="Flusk.io : Free Digital Assets"
        description="As designers and developers, We found ourself drowning in a sea of free resources scattered across the web. We sum-up all those resources for you!"
        name="flusk.io"
        type="Category"
      />
      <section className="bg-white dark:bg-gray-900">
        {loading ? (
          <HashLoader
            color="#000"
            loading={loading}
            size={50}
            cssOverride={override}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <CategoryCard category={category} productCount={productCount} />
            <ProductList title={`${category.label}`} products={products} />
            <AdSense dataAdSlot={"4834184072"} />
          </div>
        )}
      </section>
    </>
  );
};

export default Category;
