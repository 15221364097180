import React from "react";
import about1 from "./../assets/about-1.jpg";
import about2 from "./../assets/about-2.jpg";
import about3 from "./../assets/about-3.jpg";
import about4 from "./../assets/about-4.jpg";
import Seo from "../components/SEO/Seo";
import bmc from "./../assets/bmc_qr.png";

const About = () => {
  return (
    <>
      <Seo
        title="Flusk.io : Free Digital Assets"
        description="As designers and developers, We found ourself drowning in a sea of free resources scattered across the web. We sum-up all those resources for you!"
        name="flusk.io"
        type="Category"
      />
      <section className="bg-white dark:bg-gray-900">
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              Welcome to Flusk.io
            </h2>
            <p className="mb-4">
              I'm{" "}
              <a
                href="https://www.linkedin.com/in/saurab-sen/"
                className="font-medium text-black underline hover:no-underline"
              >
                Saurab Sen
              </a>
              , the creator and curator behind this digital haven born from the
              chaos of my overflowing Chrome bookmarks.
            </p>
            <p className="mb-4">
              As a passionate developer/designer, I found myself drowning in a
              sea of valuable links, struggling to keep tabs on the wealth of
              knowledge scattered across the internet. Fueled by the desire to
              streamline this process for myself and my fellow enthusiasts, I
              embarked on a journey to create a centralized space where
              creativity meets efficiency.
            </p>
            <p className="mb-4">
              What started as a personal endeavor quickly transformed into
              Flusk.io, a dedicated platform meticulously designed to organize,
              categorize, and manage the vast array of resources essential for
              bringing your next big project to life. From coding libraries to
              design inspiration, we've got it all covered.
            </p>
            <p className="mb-4">
              I understand the challenges that come with navigating the vast
              landscape of online tools and references, and I wanted to make it
              easier for everyone in the community. The result is a
              user-friendly space where developers and designers can discover,
              bookmark, and contribute to an ever-growing repository of
              knowledge.
            </p>
            <p className="mb-4">
              This project is not just about sharing resources; it's about
              fostering a sense of community and collaboration. I invite you to
              explore the site, discover new gems, and connect with me to share
              your thoughts, suggestions, or just to chat over a virtual coffee.
              Your feedback is invaluable, and I'm eager to enhance this
              platform based on your needs and ideas.
            </p>
            <p className="mb-4">
              Thank you for being a part of this journey. Together, let's build
              and create remarkable things. Cheers to innovation, learning, and
              the boundless possibilities that lie ahead!
            </p>
            <p className="mb-4">
              Connect with me, and let's make this platform even better. Cheers!
            </p>
            <p className="mb-4">
              Feel free to support the project by{" "}
              <a
                className="font-medium text-black underline hover:no-underline"
                href="https://buymeacoffee.com/saurabsen"
              >
                buymeacoffee.com/saurabsen
              </a>
            </p>
            <div className="flex justify-center">
              <img width={150} src={bmc} alt="buymecoffeeQRcode" />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mt-8">
            <img
              className="w-full rounded-lg"
              src={about1}
              alt="office content 1"
            />
            <img
              className="mt-4 w-full lg:mt-10 rounded-lg"
              src={about2}
              alt="office content 2"
            />
            <img
              className="w-full rounded-lg"
              src={about3}
              alt="office content 3"
            />
            <img
              className="mt-4 w-full lg:mt-10 rounded-lg"
              src={about4}
              alt="office content 4"
            />
          </div>
        </div>
      </section>
      {/* <Contributers /> */}
    </>
  );
};

export default About;
