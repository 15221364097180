import React from "react";
import "./category.css";
import { Link, useLocation } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

const CategoryCard = ({ category, productCount }) => {
  const location = useLocation();
  if (!category || !category.label) {
    return null;
  }

  const isCategoryInPath = location.pathname.includes("category");

  return (
    <Fade>
      <Link to={`/category/${category.value}`}>
        <span
          href="#"
          className="flex flex-col bg-black rounded-3xl basis-1/4 hover:bg-gray-900"
        >
          <div className="flex flex-col justify-start p-8 w-full leading-normal">
            <div className="flex flex-col md:flex-row justify-between">
              <div>
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-white dark:text-white">
                  {category.label}
                </h5>
                <p className="mb-3 font-normal text-white dark:text-gray-400">
                  {category.count || productCount} Curated Resources
                </p>
                {isCategoryInPath ? (
                  <p
                    className="mb-4 font-normal text-white dark:text-gray-400"
                    style={{ width: "75%", marginTop: "2rem" }}
                  >
                    Scroll down for all the free resources for your next
                    project. Most of the resources are free to use for your next
                    UI/UX, Web or mobile application.
                  </p>
                ) : (
                  ""
                )}
              </div>

              <img
                className="object-cover cursor-pointer hover:move-up-down w-full h-36 md:h-36 md:w-36 rounded-t-lg md:rounded-none md:rounded-l-lg"
                src={category.image}
                alt=""
              />
            </div>
          </div>
        </span>
      </Link>
    </Fade>
  );
};

export default CategoryCard;
