import React from "react";
import { Link } from "react-router-dom";

const Category = ({ CATEGORYLIST }) => {
  return (
    <>
      <section className="bg-white dark:bg-gray-900 my-24">
        <div className="flex flex-wrap justify-center max-w-screen-xl px-32">
          {CATEGORYLIST.map((button) => (
            <Link to={`category/${button.value}`} key={button.value}>
              <button
                key={button.id}
                type="button"
                className="text-gray-900 whitespace-nowrap bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
              >
                {button.label}
              </button>
            </Link>
          ))}
        </div>
      </section>
    </>
  );
};

export default Category;
