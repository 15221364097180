import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto my-8 p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy for Flusk.io</h1>
      <p className="mb-2">
        Effective Date: <strong>13 Sep 2024</strong>
      </p>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">
          1. Information Collection
        </h2>
        <p className="mb-2">
          We collect the following types of information when you interact with
          Flusk.io:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Contact Information:</strong> When you sign up for our
            newsletter or offers, we may collect your email address, name, and
            other relevant login information.
          </li>
          <li>
            <strong>Usage Data:</strong> We may automatically collect
            information about your interaction with the website, such as your IP
            address, browser type, and pages accessed, to improve the user
            experience.
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">2. Use of Information</h2>
        <p className="mb-2">
          The information we collect is used for the following purposes:
        </p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            <strong>Newsletter and Offers:</strong> We use your login
            information, such as your email address, to send newsletters,
            special offers, and other promotional content.
          </li>
          <li>
            <strong>Communication:</strong> We may use your contact information
            to respond to your inquiries, feedback, or requests.
          </li>
          <li>
            <strong>Website Improvement:</strong> We may analyze usage data to
            improve the functionality and resources available on Flusk.io.
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">3. Resources Disclaimer</h2>
        <p className="mb-2">
          Flusk.io provides links to third-party web resources and assets.{" "}
          <strong>We do not own or control these resources</strong> and are not
          responsible for their availability, accuracy, or changes, including if
          they become paid or unavailable. We offer these links as a service,
          and use of these resources is at your own discretion and risk.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">4. Third-Party Links</h2>
        <p className="mb-2">
          Flusk.io contains links to external websites. These third-party sites
          are not operated by us, and we have no control over their content or
          privacy practices. We recommend reviewing the privacy policies of
          these external websites before providing personal information.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">5. Cookies</h2>
        <p className="mb-2">
          Flusk.io may use cookies to enhance your browsing experience. Cookies
          help us track how users interact with our website and allow us to
          provide customized content. You can disable cookies in your browser
          settings, but doing so may affect some features of the website.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">6. Data Security</h2>
        <p className="mb-2">
          We implement reasonable security measures to protect your information
          from unauthorized access, alteration, or disclosure. However, please
          note that no method of internet transmission is completely secure, and
          we cannot guarantee the absolute security of your data.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">7. Your Rights</h2>
        <p className="mb-2">You have the right to:</p>
        <ul className="list-disc pl-6 mb-4">
          <li>
            Unsubscribe from our newsletters and offers at any time by following
            the instructions in the emails or contacting us directly.
          </li>
          <li>
            Request the removal of any resources linked on Flusk.io by
            contacting us via email.
          </li>
        </ul>
      </div>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">
          8. Changes to This Privacy Policy
        </h2>
        <p className="mb-2">
          Flusk.io reserves the right to update this Privacy Policy at any time.
          Any changes will be posted on this page, and your continued use of the
          website signifies your acceptance of any modifications.
        </p>
      </div>

      <div className="mb-4">
        <h2 className="text-2xl font-semibold mb-2">9. Contact Us</h2>
        <p className="mb-2">
          If you have any questions about this Privacy Policy, suggestions, or
          would like to request the removal of resources or unsubscribe from our
          communications, please contact us at:
        </p>
        <p className="font-semibold">Email: hello@flusk.io</p>
      </div>

      <p className="mt-6 text-sm">
        By using Flusk.io, you agree to the terms of this Privacy Policy and
        consent to the collection and use of your information as described
        above.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
