import React from "react";
import { Link, useLocation } from "react-router-dom";
import defaultImage from "./../assets/default.png";
import AdSense from "../components/AdSense/AdSense";

const SingleProduct = () => {
  const location = useLocation();
  const product = location.state.product;
  console.log(product.name);
  return (
    <section className="bg-white px-4 py-8 antialiased dark:bg-gray-900 md:py-16">
      <AdSense />
      <div className="mx-auto grid max-w-screen-xl rounded-lg bg-gray-50 p-4 dark:bg-gray-800 md:p-8 lg:grid-cols-12 lg:gap-8 lg:p-16 xl:gap-16">
        <div className="lg:col-span-5 lg:mt-0">
          {product.imagebase64 ? (
            <img
              className="h-auto max-w-full rounded-lg"
              src={product.imagebase64}
              alt=""
              style={{ width: "-webkit-fill-available" }}
            />
          ) : (
            <div
              style={{
                position: "relative",
                display: "inline-block",
                width: "100%",
              }}
            >
              <img
                className="h-auto max-w-full rounded-lg"
                src={defaultImage}
                alt=""
                style={{ width: "-webkit-fill-available" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  textAlign: "center",
                  lineHeight: "1",
                  transform: "translate(-50%, -50%)",
                  color: "black",
                  fontSize: "35px",
                  fontWeight: "bold",
                }}
              >
                {product.name}
              </div>
            </div>
          )}
        </div>
        <div className="me-auto place-self-center lg:col-span-7">
          <h1 className="mb-3 text-2xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white md:text-4xl">
            {product.name}
          </h1>
          <p className="mb-6 text-gray-500 dark:text-gray-400">
            {product.description}
          </p>
          <Link
            to={`${product.product_url}?ref=${process.env.REACT_APP_PUBLIC_URL}`}
            rel="noopener noreferrer"
            target="_blank"
            className="inline-flex items-center justify-center rounded-lg bg-primary-700 px-5 py-3 text-center text-base font-medium text-white hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
          >
            {" "}
            Visit the asset webiste{" "}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default SingleProduct;
