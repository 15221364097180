/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import ProductList from "../components/ProductList/ProductList";
import { CATEGORYLIST } from "../utils/CategoryList";
import { useAuth } from "../contexts/AuthContext";

const override = {
  display: "block",
  margin: "18% auto",
};

const Favourites = () => {
  const APIURL = process.env.REACT_APP_API;
  let { categoryType } = useParams();
  const { currentUser } = useAuth();
  const [category, setCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [productCount, setProductCount] = useState(0);

  useEffect(() => {
    let selectedCategory = CATEGORYLIST.find(
      (category) => category.value === categoryType
    );
    setCategory(selectedCategory);
  }, [categoryType]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${APIURL}user/getMyfav/${currentUser.multiFactor.user.email}`
        );
        const result = await response.json();
        setProductCount(result.length);
        setProducts(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [APIURL, categoryType, currentUser.multiFactor.user.email]);
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="py-1 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">
          <div className="mx-auto mb-8 max-w-screen-sm text-center lg:mb-16">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              My Favourites
            </h2>
            <p className="font-light text-gray-500 sm:text-xl dark:text-gray-400">
              All my favourits at one place.
            </p>
          </div>
          {currentUser ? (
            <>
              {" "}
              {loading ? (
                <HashLoader
                  color="#000"
                  loading={loading}
                  size={50}
                  cssOverride={override}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                <div className="py-1 px-4 mx-auto max-w-screen-xl lg:py-8 lg:px-6">
                  <ProductList title={`ALL MY`} products={products} />
                </div>
              )}
            </>
          ) : (
            <>
              <p className="font-light mt-16 mb-44 text-gray-500 sm:text-xl text-center dark:text-gray-400">
                Please,{" "}
                <Link className="text-black underline" to={"/login"}>
                  login
                </Link>{" "}
                to see all your favourites.
              </p>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Favourites;
