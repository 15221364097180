import React, { useEffect, useState } from "react";
import Hero from "../components/Hero/Hero";
import Category from "../components/Category/Category";
import ProductList from "../components/ProductList/ProductList";
import CategoryList from "../components/CategoryList/CategoryList";
import { COURSECATEGORYLIST } from "./../utils/CategoryList";
import { getLatest } from "./../utils/TempData";
import Seo from "../components/SEO/Seo";
import AdSense from "../components/AdSense/AdSense";

const Courses = () => {
  const APIURL = process.env.REACT_APP_API;
  const [products, setProducts] = useState(getLatest);
  const [productCount, setProductCount] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${APIURL}course/getLatest`);
        const result = await response.json();
        setProducts(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [APIURL]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${APIURL}course/all`);
        const result = await response.json();
        const categoryCount = {};
        result.forEach((item) => {
          const { category } = item;
          categoryCount[category] = (categoryCount[category] || 0) + 1;
        });
        const resultArray = Object.keys(categoryCount).map((category) => ({
          category,
          count: categoryCount[category],
        }));
        setProductCount(resultArray);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [APIURL]);

  return (
    <>
      <Seo
        title="Flusk.io: Free Digital Assets"
        description="As designers and developers, We found ourself drowning in a sea of free resources scattered across the web. We sum-up all those resources for you!"
        name="flusk.io"
        type="home"
      />
      <Hero
        subHeader={"A curated courses website"}
        header={"Free Online Courses"}
        description={
          "As designers and developers, We found ourself drowning in a sea of free courses scattered across the web. We sum-up all those courses for you!"
        }
        bgHero={
          "https://doodleipsum.com/520x588/outline?i=63527485e69dea481bb541d7f1da73fe"
        }
      />
      <AdSense />
      <Category CATEGORYLIST={COURSECATEGORYLIST} />
      <ProductList
        title={"Latest Resources"}
        href={"/course-category"}
        products={products}
      />
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <CategoryList
            CATEGORYLIST={COURSECATEGORYLIST}
            productCount={productCount}
          />
        </div>
      </section>
    </>
  );
};

export default Courses;
